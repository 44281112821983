<template>
  <div>
    <nav>
      <div class="logo">
        <img
          src="./../../assets/Consltunt panel_web_design/Consultant Panel.png"
          alt=""
        />
      </div>
      <div class="company-name">REDJINNI INDUSTRIES PVT. LTD.</div>
      <div class="login-signup">
       
         <button
          @click="$router.push('/the-phoenix-tale')"
          style="background-color: white !important; color: #0c2d61; border: 1px  solid #0c2d61; border-radius: 7px;"
        >
          The Phoenix Tale
        </button>
        <button
          class="hover-bg-color"
          style="color: #0c2d61; border: 1px  solid #0c2d61; border-radius: 7px"
          @click="$router.push('/ib-talks')"
        >
          IBTalks
        </button>
        <button
          class="hover-bg-color"
          style="color: #0c2d61; border: 1px  solid #0c2d61; border-radius: 7px;"
          @click="$router.push('/blogs')"
        >
          Blog
        </button>
        <button @click="login">Login</button>
        <button @click="signup">Signup</button>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",

  methods: {
    signup() {
      this.$router.push("/login");
    },
    login() {
      // Add your signup logic here
      this.$router.push("/login");
      localStorage.setItem("signup", "true");
    },
  },
};
</script>

<style scoped>
.hover-bg-color {
  background-color: white !important;
}
.hover-bg-color:hover {
  background-color: #0c2d61 !important;
  color: #ffffff !important;
}

nav {
  background-color: white;
  color: #0c2d61;
  padding: 15px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.logo {
  margin-top: -5px;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
}

.login-signup {
  gap: 10px;
}

.login-signup button {
  padding: 10px 25px;
  margin-left: 10px;
  font-size: 14px;
  background: #0c2d61;
  border: none;
  color: white;
  font-weight: 600;
  box-shadow: 0p 10px 2px gray;
  /* -webkit-animation-fill-mode: ; */
  border-radius: 7px;
}
</style>
